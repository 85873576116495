import SearchButtonIcon from '@/assets/img/search-button.png';
import { breakpoint } from '@/helpers/BreakpointHelper';
import LayoutWithoutTitle from '@/layouts/LayoutWithoutTitle';
import React, { useEffect } from 'react';
import styled from 'styled-components';

const SearchForm = styled.form`
  position: relative;
  width: 560px;
  margin: auto;

  ${breakpoint(`mobile`)} {
    padding: 20px;
    width: 100vw;
  }
`;

const SearchBar = styled.input`
  display: block;
  border: 1px solid #e5e5e5;
  margin: auto auto 30px;
  height: 60px;
  //min-width: 560px;
  color: #333333;
  font-size: 18px;
  letter-spacing: -0.05em;
  font-family: 'Nanum Barun Gothic';
  outline: none;
  padding: 0 20px;
`;

const SearchResult = styled.div`
  box-shadow: 0px 5px 10px rgb(0 0 0 / 14%);
  background-color: #fff;
  padding: 50px;
  min-height: 290px;
  width: 1050px;
  margin: auto;
  display: block;
  position: relative;

  ${breakpoint(`tablet`)} {
    margin: auto;
    padding: 10px;
    width: 100vw;
  }
`;

const SearchButton = styled.button`
  position: absolute;
  top: 17px;
  right: 20px;

  ${breakpoint(`mobile`)} {
    top: 35px;
    right: 40px;
  }
`;

function index({ location }) {
  useEffect(() => {
    (function () {
      const cx = `000556451291554713052:lnlt2cb2m7e`;
      const gcse = document.createElement(`script`);
      gcse.type = `text/javascript`;
      gcse.async = true;
      gcse.src = `https://cse.google.com/cse.js?cx=${cx}`;
      const s = document.getElementsByTagName(`script`)[0];
      s.parentNode.insertBefore(gcse, s);
    })();
  }, []);

  console.log(location);

  return (
    <LayoutWithoutTitle>
      <SearchForm action="/search">
        <SearchBar
          id="searchText"
          name="q"
          placeholder="검색어를 입력하세요."
        />
        <input
          type="hidden"
          id="cx"
          name="cx"
          value="000556451291554713052:lnlt2cb2m7e"
        />

        <SearchButton type="submit" name="search-button" title="검색하기">
          <button type="submit" name="search-button" title="검색하기">
            <img src={SearchButtonIcon} alt="" />
          </button>
        </SearchButton>
      </SearchForm>
      <SearchResult>
        <div className="gcse-searchresults-only" data-newWindow="false" />
      </SearchResult>
    </LayoutWithoutTitle>
  );
}

export default index;
