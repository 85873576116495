import BaseLayout from './BaseLayout';
import Breadcrumb from '@/components/Breadcrumb';
import Container from '@/components/Container';
import { IdContainer, NullSection } from '@/components/Css/Layout';
import Footer from '@/components/Footer';
import Header from '@/components/Header';
import Section from '@/components/Section';
import ROUTES, {
  getRoutePath,
  ServerRoute,
  serverToRoute,
} from '@/config/routes';
import { graphql, PageProps, useStaticQuery } from 'gatsby';
import React, { FC } from 'react';

const LayoutWithoutTitle: FC<{
  location?: PageProps['location'];
  hideGnb?: boolean;
  hideMargin?: boolean;
}> = ({ children, location, hideGnb, ...props }) => {
  const {
    allMenu: { nodes: serverMenus },
  } = useStaticQuery<{
    allMenu: { nodes: ServerRoute[] };
  }>(graphql`
    query {
      allMenu {
        nodes {
          id
          level
          menuCode
          menuName
          menuUrl
          offspring {
            level
            menuCode
            menuParentCode
            menuColumnOrder
            menuName
            menuUrl
            offspring {
              level
              menuCode
              menuName
              menuParentCode
              menuUrl
            }
          }
        }
      }
    }
  `);
  const menus = [...serverMenus.map(serverToRoute), ...ROUTES];

  const uri = location?.pathname.replace(/\/$/, ``);
  const routePath = uri ? getRoutePath(uri, menus) : [];
  return (
    <BaseLayout>
      <Header routePath={routePath} hideGnb={hideGnb} />
      <NullSection hideGnb={hideGnb} />
      {!props.hideMargin ? (
        <Section
          backgroundColor="transparent"
          css={`
            padding: 32px 0 20px 0;
          `}
        >
          <Container>
            <Breadcrumb routePath={routePath} />
          </Container>
        </Section>
      ) : null}
      <IdContainer>{children}</IdContainer>
      <Footer />
    </BaseLayout>
  );
};

export default LayoutWithoutTitle;
